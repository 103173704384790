.chart-container {
  width: 100%;
  height: 450px; /* Increased base height */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  margin-top: 60px;
}

.recharts-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.recharts-surface {
  width: 100%;
  height: 100%;
}

@media (min-width: 640px) {
  .chart-container {
    height: 550px;
  }
}

canvas {
  max-width: 100%;
  height: auto;
}

.chartjs-legend {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.MuiBox-root.css-k008qs {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
