canvas {
    width: 80%;
    height: 70vh;
}

.chartjs-legend {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap; /* Wrap the legend items */
    justify-content: center; /* Center the items */
    gap: 10px; /* Add space between items */
}